import React, { useState, useEffect, useMemo } from "react";

import {
  Grid,
  AppBar,
  Tabs,
  Tab,
  Button,
  IconButton,
  CircularProgress,
  Fab,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Paper,
  Typography,
  TextField,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import Select, { ActionMeta } from "react-select";
import { useHistory } from "react-router-dom";

import ExitIcon from "@material-ui/icons/ExitToApp";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import TabPanel from "components/TabPanel";

import logoBranco from "assets/img/zigpark.webp";

import axios from "config/axios";

import { Boards, ReactSelectData, ReactSelect, UserData } from "types";

import useStyles, { selectStyles } from "./styles";

const Main = () => {
  const [tab, setTab] = useState(0),
    [alert, setAlert] = useState(""),
    [alertSuccess, setAlertSuccess] = useState(""),
    [loading, setLoading] = useState(false),
    [selectedBoard, setSelectedBoard] = useState<string | undefined>(""),
    [hideSelector, setHideSelector] = useState(false),
    [confLogout, setConfLogout] = useState(false),
    [email, setEmail] = useState(""),
    [userData, setUserData] = useState<UserData>(),
    [boards, setBoards] = useState<ReactSelectData>([]);

  const history = useHistory();

  const classes = useStyles();

  const getBoards = async () => {
    axios
      .get("/valet/boards")
      .then(({ data }: { data: Boards }) => {
        const result: ReactSelectData = [];
        data.forEach((board) => {
          let type = "Entrada";
          switch (board.operationType) {
            case "in":
              type = "Cancela entrada";
              break;
            case "out":
              type = "Cancela saída";
              break;
            case "in_out":
              type = "Cancela mista";
              break;
            default:
              break;
          }
          result.push({ value: board._id, label: `${type} ${board.name}` });
        });
        setBoards(result);
      })
      .catch((error) => {
        console.log(error);
        setAlert("Problema ao carregar cancelas");
      });
  };

  const simplyOpen = async () => {
    if (!selectedBoard) {
      return setAlert("Selecione uma cancela");
    }
    setLoading(true);
    axios
      .post("/valet/simply/open/board", {
        boardId: selectedBoard,
      })
      .then(() => {
        setLoading(false);
        setAlertSuccess("Cancela aberta com sucesso");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        switch (error?.response?.data?.status) {
          case 408:
            return setAlert("Problema de comunicação com a cancela");
          case 1:
            return setAlert("Usuário não encontrado");
          case 2:
            return setAlert("Cancela não encontrada");
          case 3:
            return setAlert("Senha incorreta");
          case 5:
            return setAlert("Veículo não detectado");
          default:
            return setAlert("Problema ao abrir");
        }
      });
  };

  useEffect(() => {
    getBoards();
  }, []);

  const changeBoardSelection = async (
    data: ReactSelect | null,
    actionMeta: ActionMeta<ReactSelect>
  ) => {
    setSelectedBoard(data?.value);
  };

  const logout = async () => {
    window.sessionStorage.removeItem("@ZigparkParkValet:token");
    history.push("/");
  };

  const getUserData = async () => {
    if (!email) {
      return setAlert(
        "Preencha o campo com o email da pessoa que irá pagar o estaciomento"
      );
    }
    setLoading((oldState) => !oldState);
    axios
      .post("/valet/find/user", {
        email,
      })
      .then(({ data }: { data: UserData }) => {
        setLoading((oldState) => !oldState);
        setUserData(data);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        switch (error?.response?.data?.status) {
          case 404:
            return setAlert("Usuário não encontrado");
          case 1:
            return setAlert("A pessoa não está estacionada no local");
          default:
            return setAlert("Problema ao carregar dados");
        }
      });
  };

  const releaseUser = async () => {
    setLoading((oldState) => !oldState);
    axios
      .post("/valet/release/user", {
        userId: userData?.log?.user._id,
        finalInfo: userData?.finalInfo,
        subtotal: userData?.subtotal,
      })
      .then(() => {
        setLoading((oldState) => !oldState);
        setAlertSuccess("Liberado com sucesso");
        setEmail("");
        setUserData(undefined);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        switch (error?.response?.data?.status) {
          case 404:
            return setAlert("Usuário não encontrado");
          case 1:
            return setAlert("A pessoa não está estacionada no local");
          default:
            return setAlert("Problema ao carregar dados");
        }
      });
  };

  useEffect(() => {
    if (boards.length === 1) {
      setSelectedBoard(boards[0]?.value);
      setHideSelector(true);
    }
  }, [boards]);

  useEffect(() => {
    setTimeout(() => {
      setAlert("");
    }, 15000);
  }, [alert]);

  useEffect(() => {
    setTimeout(() => {
      setAlertSuccess("");
    }, 15000);
  }, [alertSuccess]);

  const price = useMemo(() => {
    if (!userData) {
      return "";
    }
    if (new Date() >= new Date(userData?.log?.release) && userData?.log.payed) {
      return "Vencido";
    }
    if (userData?.log?.payed) {
      return "Já pago";
    }
    if (userData?.log?.monthlyUser) {
      return "Mensalista";
    }
    if (userData?.finalInfo?.value <= 0) {
      return "Isento (apenas libere o pagamento)";
    } else {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(userData?.finalInfo?.value || 0);
    }
  }, [userData]);

  return (
    <>
      <div className={classes.background}>
        <img
          src={logoBranco}
          alt="Logo park lockyt"
          className={classes.backgroundImg}
        />
      </div>
      <div>
        {alert && (
          <Alert variant="filled" severity="error">
            <AlertTitle>{alert}</AlertTitle>
          </Alert>
        )}
        {alertSuccess && (
          <Alert variant="filled" severity="success">
            <AlertTitle>{alertSuccess}</AlertTitle>
          </Alert>
        )}
        <AppBar position="static" color="secondary">
          <Tabs
            indicatorColor="primary"
            value={tab}
            onChange={(e, newValue) => {
              setEmail("");
              setTab(newValue);
            }}
            aria-label="Menu de opções"
            variant="fullWidth"
          >
            <Tab label="Cancelas" disabled={loading} />
            <Tab label="Pagar" disabled={loading} />
          </Tabs>
        </AppBar>
        <TabPanel value={tab} index={0} className={classes.container}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h5">Abrir cancela</Typography>
            <Grid
              container
              direction="column"
              alignItems="center"
              spacing={2}
              style={{ marginTop: 10 }}
            >
              {!hideSelector && (
                <Grid item style={{ flex: 1, width: "90%" }}>
                  <Select
                    options={boards}
                    onChange={changeBoardSelection}
                    //value={defaultOption}
                    placeholder="Selecione a cancela a ser aberta"
                    styles={selectStyles}
                  />
                </Grid>
              )}
              <Grid item>
                <div className={classes.wrapper}>
                  <Button
                    onClick={simplyOpen}
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                  >
                    Clique para abrir a cancela
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Paper>
        </TabPanel>
        <TabPanel value={tab} index={1} className={classes.container}>
          {userData ? (
            <>
              <IconButton
                onClick={() => setUserData(undefined)}
                className={classes.backBtn}
              >
                <ArrowBackIcon />
              </IconButton>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h5">Realizar pagamento</Typography>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  spacing={2}
                  style={{ marginTop: 10 }}
                >
                  <Grid item>
                    <Typography variant="body1" align="center">
                      Nome da pessoa: <b>{userData?.log?.user?.name}</b>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" align="center">
                      Valor a ser pago: <b>{price}</b>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <div className={classes.wrapper}>
                      <Button
                        onClick={releaseUser}
                        variant="contained"
                        color="secondary"
                        disabled={
                          loading ||
                          (new Date() <= new Date(userData?.log?.release) &&
                            userData?.log.payed) ||
                          userData?.log?.monthlyUser
                            ? true
                            : false
                        }
                      >
                        {new Date() > new Date(userData?.log?.release) &&
                        userData?.log.payed
                          ? "Renovar tempo de saída"
                          : "Liberar pagamento"}
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </>
          ) : (
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h5">Liberar pagamento</Typography>
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={2}
                style={{ marginTop: 10 }}
              >
                <Grid item style={{ flex: 1, width: "80%" }}>
                  <TextField
                    label="Email da pessoa"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <div className={classes.wrapper}>
                    <Button
                      onClick={getUserData}
                      variant="contained"
                      color="secondary"
                      disabled={loading}
                    >
                      Continuar
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Paper>
          )}
        </TabPanel>
        <Fab
          color="secondary"
          aria-label="Sair"
          className={classes.fab}
          onClick={() => setConfLogout(true)}
        >
          <ExitIcon />
        </Fab>
        <Dialog
          open={confLogout}
          onClose={() => setConfLogout(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Sair da conta?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deseja sair de sua conta?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfLogout(false)} color="primary">
              Voltar
            </Button>
            <Button onClick={logout} color="primary" autoFocus>
              Sair
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Main;
