import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import mainTheme from "./theme";

import reportWebVitals from "./reportWebVitals";

import Main from "views/Main";
import { Login } from "views/Auth";

import "assets/css/main.css";

import isAuthenticated from "functions/isAuthenticated";

const hist = createBrowserHistory();

interface IProps {
  [key: string]: any;
}

// eslint-disable-next-line react/prop-types
function PrivateRoute({ children, ...rest }: IProps) {
  return (
    <Route
      {...rest}
      render={() => (isAuthenticated() ? children : <Redirect to="/" />)}
    />
  );
}

ReactDOM.render(
  <ThemeProvider theme={mainTheme}>
    <Router history={hist}>
      <Switch>
        <PrivateRoute path="/valet">
          <Route component={Main} />
        </PrivateRoute>
        {isAuthenticated() && <Redirect to="/valet" />}
        <Route path="/" component={Login} />
      </Switch>
    </Router>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
