import React, { useState } from "react";
import {
  Grid,
  LinearProgress,
  Button,
  Card,
  CardContent,
  FormControl,
  Input,
  InputLabel,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import axios from "config/axios";
import { AxiosError } from "axios";

//import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";

import image from "assets/img/bg.webp";
import logo from "assets/img/zigpark.webp";

import useStyles from "./styles";

function Login() {
  let [loading, setLoading] = useState(false),
    [login, setLogin] = useState(""),
    [alert, setAlert] = useState(""),
    [password, setPassword] = useState("");

  let history = useHistory(),
    classes = useStyles();

  const verify = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/valet/login", {
        login,
        password,
      })
      .then(({ data }: { data: { token: string } }) => {
        setLoading(false);
        sessionStorage.setItem("@ZigparkParkValet:token", data.token);
        history.push("/valet");
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        switch (error?.response?.status) {
          case 400:
            setAlert("Senha inválida");
            break;
          case 404:
            setAlert("Usuário não encontrado");
            break;
          default:
            setAlert("Problema ao entrar, tente novamente");
            break;
        }
      });
  };

  return (
    <div
      className={classes.backgroundImage}
      style={{
        backgroundImage: "url(" + image + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <div>
        {loading ? <LinearProgress color="secondary" /> : <></>}
        {alert && (
          <Alert
            variant="filled"
            severity="error"
            action={
              <IconButton
                color="inherit"
                size="small"
                onClick={() => setAlert("")}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle> {alert}</AlertTitle>
          </Alert>
        )}
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.logos}
        >
          <Grid item className={classes.gridLogos}>
            <a
              href="https://park.lockyt.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={logo}
                alt="Logomarca park lockyt"
                className={classes.logoLockyt}
              />
            </a>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <Card className={classes.root}>
              <CardContent>
                <form onSubmit={verify}>
                  <FormGroup>
                    <FormControl className={classes.input}>
                      <InputLabel htmlFor="login">Login de acesso </InputLabel>
                      <Input
                        id="login"
                        type="text"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                      />
                    </FormControl>
                    <FormControl className={classes.input}>
                      <InputLabel htmlFor="password">Senha </InputLabel>
                      <Input
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FormControl>
                    <Button size="small" className={classes.btn} type="submit">
                      Entrar
                    </Button>
                  </FormGroup>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      {/*<Footer />*/}
    </div>
  );
}

export default Login;
