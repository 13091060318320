import { makeStyles, createStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

export const selectStyles = {
  option: (
    styles: any,
    {
      isDisabled,
      isFocused,
      isSelected,
    }: { isDisabled: boolean; isFocused: boolean; isSelected: boolean }
  ) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#8a6eb2" : isFocused && "#05b5a9",
      color: isSelected ? "white" : isFocused && "white",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
      },
    };
  },
};

const useStyles = makeStyles((theme) =>
  createStyles({
    background: {
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      height: "100%",
      zIndex: -1,
      backgroundColor: "#545454",
    },
    backgroundImg: {
      position: "absolute",
      left: "36.5%",
      top: "70%",
      width: "28%",
    },
    container: {
      padding: 10,
    },
    paper: {
      padding: 20,
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    backBtn: {
      backgroundColor: "white",
      marginBottom: 10,
    },
  })
);

export default useStyles;
